import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import Button from '../../components/atoms/Forms/Button/Button'
import { useHistory } from 'react-router-dom'
import { Footer } from '../HomePage/Footer'
import tpayImage from '../../assets/images/tpay-logotypy.png'

const RegulaminWplat = ({ topMargin = 10, bottomMargin = 20, width = 80 }) => {
  const t = LocalStorageHelper.get('translation')
    const history = useHistory()

    const handleGoHome = () => {
      history.push('/')
    }

    const tPayImageVar = () => {
      // Import result is the URL of your image
      return <img src={tpayImage} alt="tpay_image" />
    }
  const styles = {
    marginTop: `${topMargin}px`,
    marginBottom: `${bottomMargin}px`,
    width: `${width}%`,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Lifesurfers</title>
        <meta name="description" content="Zarejestruj się i dołącz do najbliższego wydarzenia, aby poczuć magię planszówek." />
        <meta name="keywords" content="gry planszowe, Szczecin, planszówki Lifesurfers" />
        <meta name="author" content="Jan Szymański, kontakt@your-site.pl" />
        <meta name="og:title" content="Lifesurfers" />
        <meta name="og:type" content="website" />
        <meta name="og:url" content="https://aplikacja.lifesurfers.pl" />
        <meta name="og:site_name" content="Lifesurfers" />
        <meta name="og:description" content="Zarejestruj się i dołącz do najbliższego wydarzenia, aby poczuć magię planszówek." />
      </Helmet>

      <div style={styles}>
      <div className="terms-of-donations">
  <h2>Regulamin przekazywania darowizn online poprzez FaniPAY tPay to jest Krajowy Integrator Płatności S.A.</h2>
  <ol>
    <li>
      <p>
        Strona internetowa www.lifesurfers.pl prowadzona i administrowana jest przez Fundację Life Surfers
        z siedzibą w Szczecinie, ul. Parkowa 1/3, 71-600, NIP 8522638007, REGON 368901643,
        KRS 0000707010, zwaną dalej Fundacją Life Surfers.
      </p>
    </li>
    <li>
      <p>
        Niniejszy dokument ściśle określa warunki przekazywania dobrowolnych darowizn pieniężnych online
        realizowanych poprzez serwis FaniPAY tPay to jest Krajowy Integrator Płatności S.A.
      </p>
    </li>
    <li>
      <p>
        Darczyńcą może być pełnoletnia osoba fizyczna, osoba prawna lub jednostka organizacyjna
        nieposiadająca osobowości prawnej, zwana dalej Darczyńcą.
      </p>
    </li>
    <li>
      <p>
        Darowizny można przekazywać 24 godziny na dobę. Fundacja Life Surfers nie odpowiada za przerwy
        w dostępie do serwisu FaniPAY tPay to jest Krajowy Integrator Płatności S.A.
      </p>
    </li>
    <li>
      <p>
        Serwis FaniPAY tPay to jest Krajowy Integrator Płatności S.A. umożliwia dokonywanie płatności w formie darowizn na rzecz Fundacji Life Surfers.
        Przekierowanie do serwisu FaniPAY tPay to jest Krajowy Integrator Płatności S.A. następuje poprzez korzystanie z elektronicznego formularza
        umieszczonego na stronie www.lifesurfers.pl:
      <ul>
        <li>Karty płatnicze: Visa, Visa Electron, MasterCard, MasterCard Electronic, Maestro</li>
        <li>Płatności online</li>
        <li>Płatności mobilne BLIK</li>
      </ul>
      </p>
      <img src={tpayImage} alt="tpayImage" width="50%" height="10%"></img>
    </li>
    <li>
      <p>
        Podmiotem świadczącym obsługę płatności online drogą elektroniczną jest Krajowy Integrator Płatności S.A.
      </p>
    </li>
    <li>
      <p>
        W przypadku wystąpienia konieczności zwrotu środków za transakcję dokonaną przez wpłacającego kartą płatniczą,
        przyjmujący wpłatę dokonana zwrotu na rachunek bankowy przypisany do karty płatniczej Wpłacającego.
      </p>
    </li>
    <li>
      <p>
        Fundacja Life Surfers zapewnia ochronę danych osobowych zgodnie z Ustawą o ochronie danych osobowych
        z dnia 29 sierpnia 1997 r. oraz Ustawą o świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002 r.
        Administratorem danych uzyskanych w procesie przekazywania darowizn online jest Fundacja Life Surfers.
        Administratorem danych uzyskanych w ramach usługi płatności online jest FaniPAY tPay. Szczegółowe dane na temat ochrony
        danych osobowych zgodnie z ustawą „RODO” znajdują się w pliku „Polityka prywatności” na stronie www.lifesurfers.pl.
      </p>
    </li>
    <li>
      <p>
        Usługodawca przetwarza dane osobowe zgodnie z obowiązującymi przepisami, w szczególności prawa polskiego oraz
        Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych
        w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy
        95/46/WE (ogólne rozporządzenie o ochronie danych, RODO). Fundacja Life Surfers zapewnia, iż dane osobowe darczyńców
        przetwarzane będą wyłącznie dla celów realizacji darowizny. Korzystanie z płatności online jest równoznaczne ze zgodą
        na przetwarzanie danych osobowych w celu realizacji tej płatności.
      </p>
    </li>
    <li>
      <p>
        Darczyńca ma prawo żądania uzupełnienia, uaktualnienia, sprostowania danych osobowych, czasowego lub stałego ich
        przetwarzania lub ich usunięcia, jeżeli są one niekompletne, nieaktualne, nieprawdziwe lub zostały zebrane z naruszeniem
        prawa albo są już zbędne do realizacji celu, dla którego zostały zebrane, a także ma prawo wniesienia pisemnego żądania
        zaprzestania przetwarzania jego danych osobowych.
      </p>
    </li>
    <li>
      <p>
        Fundacja Life Surfers nie będzie przetwarzać ani udostępniać innym podmiotom danych o darczyńcach i dokonanych przez
        nich wpłat darowizn bez ich wiedzy i zgody.
      </p>
    </li>
    <li>
      <p>
        Fundacja Life Surfers zastrzega sobie prawo do zmiany niniejszego regulaminu w każdej chwili z ważnych przyczyn.
      </p>
    </li>
    <li>
      <p>
        Zmiany są wiążące dla użytkowników z chwilą ich publikacji na stronie.
      </p>
    </li>
    <li>
      <p>
        W sprawach nieuregulowanych niniejszym regulaminem stosuje się odpowiednie przepisy prawne. Kwestie sporne rozpatruje
        sąd właściwy miejscowo i rzeczowo.
      </p>
    </li>
  </ol>
</div>
        <Button
          sx={{ minWidth: BreakpointsHelper.isMobile() ? '100%' : '100px' }}
          onClick={handleGoHome}
        >
          Wróć
        </Button>
      </div>
      <Footer />
    </div>
  )
  }
export default RegulaminWplat
