import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import Button from '../../components/atoms/Forms/Button/Button'
import { useHistory } from 'react-router-dom'
import { Footer } from '../HomePage/Footer'
import tpayImage from '../../assets/images/tpay-logotypy.png'

const RegulaminStrony = ({ topMargin = 10, bottomMargin = 20, width = 80 }) => {
  const t = LocalStorageHelper.get('translation')
    const history = useHistory()

    const handleGoHome = () => {
      history.push('/')
    }

    const tPayImageVar = () => {
      // Import result is the URL of your image
      return <img src={tpayImage} alt="tpay_image" />
    }
  const styles = {
    marginTop: `${topMargin}px`,
    marginBottom: `${bottomMargin}px`,
    width: `${width}%`,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Lifesurfers</title>
        <meta name="description" content="Zarejestruj się i dołącz do najbliższego wydarzenia, aby poczuć magię planszówek." />
        <meta name="keywords" content="gry planszowe, Szczecin, planszówki Lifesurfers" />
        <meta name="author" content="Jan Szymański, kontakt@your-site.pl" />
        <meta name="og:title" content="Lifesurfers" />
        <meta name="og:type" content="website" />
        <meta name="og:url" content="https://aplikacja.lifesurfers.pl" />
        <meta name="og:site_name" content="Lifesurfers" />
        <meta name="og:description" content="Zarejestruj się i dołącz do najbliższego wydarzenia, aby poczuć magię planszówek." />
      </Helmet>

      <div style={styles}>
      <div className="terms-of-service">
  <h1>REGULAMIN SERWISU INTERNETOWEGO</h1>

  <h2>I. Postanowienia ogólne</h2>
  <p>
    1. Serwis internetowy działający pod głównym adresem „www.lifesurfers.pl”
    zwany dalej „Serwisem” prowadzony jest przez Fundację Life Surfers, z siedzibą
    w Szczecinie przy ul. Parkowej 1/3, 71-600 Szczecin, zarejestrowaną w KRS
    pod numerem: 0000707010, NIP: 8522638007, REGON: 368901643 zwaną dalej Fundacją.
  </p>
  <p>
    2. Niniejszy regulamin, zwany dalej „Regulaminem”, określa warunki korzystania
    z Serwisu oraz zasady świadczenia usług drogą elektroniczną przez Fundację.
  </p>
  <p>
    3. Zadaniem Serwisu jest prezentacja informacji dotyczących organizacji
    wydarzeń organizowanych przez Fundację Life Surfers.
  </p>
  <p>
    4. Serwis umożliwia ponadto:
    <ul>
      <li>dostęp do treści udostępnianych przez Fundację.</li>
      <li>zapisanie się na organizowane wydarzenie.</li>
      <li>dokonywanie płatności online za udział w wydarzeniu.</li>
      <li>zapis do usługi newslettera prowadzonego za pośrednictwem wiadomości e-mail.</li>
    </ul>
  </p>
  <p>
    5. Do wszelkich publikacji oraz zdjęć umieszczonych w Serwisie prawa
    przysługują Fundacji, a ich użycie bez zgody jest zabronione. Tylko materiały
    oznaczone frazą „Do pobrania” mogą być pobrane zgodnie z prawem. Również
    znak słowny i graficzny Fundacji są chronione prawem znaków towarowych.
  </p>
  <p>
    6. W przypadku rezygnacji osoby zarejestrowanej na wydarzenie oraz
    dokonaniu przez nią płatności, organizatorzy nie przewidują możliwości zwrotu
    pieniędzy.
  </p>
  <h2>II. Świadczenie usług drogą elektroniczną</h2>
  <p>
    1. Usługi świadczone drogą elektroniczną obejmują m.in.:
    <ul>
      <li>korzystanie z newslettera.</li>
      <li>zapisanie się na organizowane wydarzenie.</li>
      <li>dokonywanie płatności online.</li>
    </ul>
  </p>
  <p>
    2. Do korzystania z usług drogą elektroniczną niezbędne są: dostęp do Internetu,
    aktywne konto poczty elektronicznej oraz urządzenie umożliwiające przeglądanie stron internetowych.
  </p>
  <p>
    3. Każdy użytkownik Serwisu, korzystając z usług drogą elektroniczną, zobowiązany jest do przestrzegania postanowień Regulaminu.
  </p>
  <p>
    4. Umowa o świadczenie usług drogą elektroniczną zostaje zawarta na czas nieokreślony w momencie rozpoczęcia korzystania z Serwisu.
  </p>
  <p>
    5. Użytkownik może w każdym czasie zrezygnować z usług świadczonych drogą elektroniczną, wysyłając odpowiednie żądanie na adres e-mail fundacja@lifesurfers.pl Fundacji.
  </p>
  <p>
    6. Zakazane jest dostarczanie przez użytkowników Serwisu treści o charakterze bezprawnym, w tym treści obraźliwych,
    naruszających prawa osób trzecich lub niezgodnych z obowiązującymi przepisami prawa.
  </p>

  <h2>III. Płatność online</h2>
  <p>
    1. Serwis umożliwia dokonywanie płatności online za udział w wydarzeniach organizowanych przez Fundację.
  </p>
  <p>
    2. Płatności online obsługiwane są przez serwis płatności tPay to jest Krajowy Integrator Płatności S.A. z siedzibą w Poznaniu, plac Andersa 3, 17 piętro, 61-894 Poznań.
    Przekierowanie do płatności następuje przez kliknięcie odpowiedniego linka w Serwisie.
  </p>
  <tPayImageVar></tPayImageVar>
            <img src={tpayImage} alt="tpayImage" width="50%" height="10%"></img>
  <p>3. Wszelkie należności muszą być wyrażone w złotych (PLN).</p>
  <p>
    4. Możliwe formy płatności: Blik, internetowy przelew bankowy, karta kredytowa lub debetowa.
  </p>
  <p>
    5. W przypadku rezygnacji z uczestnictwa w wydarzeniu po dokonaniu płatności, Fundacja nie przewiduje zwrotu wpłaconych środków.
  </p>

  <h2>IV. Newsletter</h2>
  <p>
    1. Newsletter jest wysyłany cyklicznie i bezterminowo za pośrednictwem
    internetowej poczty elektronicznej w formie listu elektronicznego na adres
    Odbiorcy i zawiera aktualne informacje oraz materiały promocyjne na temat
    wydarzeń organizowanych przez Fundację Life Surfers.
  </p>
  <p>
    2. Każdy korzystający z usługi Newslettera jest jej Odbiorcą i decydując się na
    korzystanie z usługi potwierdza zapoznanie się z regulaminem i godzi na
    stosowanie jego treści.
  </p>
  <p>
    3. Zapisanie się na usługę korzystania z Newslettera jest dobrowolne.
    Następuje ono poprzez dokonanie rejestracji adresu e-mail na stronie
    internetowej Forum pod adresem www.lifesurfers.pl.
  </p>
  <p>
    4. Aby korzystać z newslettera, obowiązkowe jest podanie adresu e-mail. W
    celu otrzymywania newslettera potrzebne jest urządzenie z dostępem do
    Internetu oraz aktywny adres e-mail.
  </p>
  <p>
    5. Dany adres poczty elektronicznej może być zarejestrowany tylko raz.
    Ponowna rejestracja możliwa jest tylko po uprzedniej rezygnacji z usługi.
  </p>
  <p>6. Rezygnacja z usługi jest możliwa w każdym czasie.</p>

  <h2>V. Ochrona danych osobowych</h2>
  <p>
    1. Zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych osobowych z dnia
    27 kwietnia 2016 r. (RODO) informujemy, że przekazane przez Państwa dane osobowe
    będą przetwarzane w celach statutowych i w związku z realizowanymi usługami.
  </p>
  <p>
    2. Dane osobowe użytkowników przetwarzane są w celu realizacji umowy o świadczenie
    usług drogą elektroniczną oraz informowania o działalności Fundacji.
  </p>
  <p>
    3. Dane osobowe są przechowywane do momentu wniesienia sprzeciwu przez użytkownika.
  </p>
  <p>
    4. Użytkownik ma prawo do żądania dostępu do swoich danych osobowych, ich sprostowania,
    usunięcia lub ograniczenia przetwarzania, a także wniesienia sprzeciwu wobec przetwarzania w celach innych niż statutowe.
  </p>
  <p>
    5. Administrator danych osobowych: Fundacja Life Surfers, tel. 500-377-540,
    e-mail: fundacja@lifesurfers.pl.
  </p>
  <p>
    6. Dane osób dokonujących płatności online przekazywane są podmiotowi
    obsługującemu płatności zgodnie z wymogami przepisów prawa.
  </p>

  <h2>VI. Postanowienia końcowe</h2>
  <p>
    1. Fundacja zastrzega sobie prawo do zmiany niniejszego Regulaminu. Zmiany wchodzą w życie z dniem
    ich publikacji na stronie Serwisu.
  </p>
  <p>
    2. W sprawach nieuregulowanych w niniejszym Regulaminie zastosowanie mają przepisy Kodeksu cywilnego oraz
    ustawy o świadczeniu usług drogą elektroniczną.
  </p>
  <p>
    3. Fundacja nie ponosi odpowiedzialności za przerwy w dostępie do Serwisu spowodowane siłą wyższą,
    awariami technicznymi lub innymi niezależnymi od Fundacji przyczynami.
  </p>
  <p>
    4. Fundacja ma prawo zaprzestać prowadzenia którejkolwiek z form wyżej
    wymienionych.
  </p>
  <p>
    5. Reklamacje dotyczące funkcjonowania form wyżej wymienionych należy
    kierować pod adres e-mail: fundacja@lifesurfers.pl. Rozpatrywane będą w
    terminie do 21 dni od dostarczenia. Reklamacja powinna zawierać imię i nazwisko
    osoby zgłaszającej, opis problemu oraz żądania użytkownika.
  </p>
</div>
        <Button
          sx={{ minWidth: BreakpointsHelper.isMobile() ? '100%' : '100px' }}
          onClick={handleGoHome}
        >
          Wróć
        </Button>
      </div>
      <Footer />
    </div>
  )
  }
export default RegulaminStrony
