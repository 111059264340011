import React from 'react'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import przemek from '../../../assets/images/contact/przemek.jpg'
import jacek from '../../../assets/images/contact/jacek.jpg'
import wiola from '../../../assets/images/contact/wiola.jpg'
import malgorzata from '../../../assets/images/contact/malgorzata.jpg'
import kamil from '../../../assets/images/contact/kamil.jpg'
import maciej from '../../../assets/images/contact/maciej.jpg'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'
import Carousel from '../../atoms/Carousel/Carousel'

const Contacts = () => {
  const t = LocalStorageHelper.get('translation')
  const style = {
    image: {
      margin: '0 auto 20px auto',
      width: '150px',
      height: '150px',
      maxWidth: '100%',
      objectFit: 'cover',
      borderRadius: '20px',
    },
    box: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '300px',
      flexDirection: 'column',
    },
    organizer: {
      fontSize: '20px',
      fontWeight: '500',
      textTransform: 'uppercase',
      position: 'relative',
      bottom: '-10px',
    }
  }
  const admins = [
    {
      name: 'Przemysław Szymański',
      image: przemek,
      phone: '500377540',
      position: 'Prezes Zarządu',
      messenger: 'https://m.me/przemyslaw.szymanski.14',
    },
    {
      name: 'Małgorzata Szymańska',
      image: malgorzata,
      phone: '501136344',
      position: 'Członek Zarządu',
      messenger: 'https://m.me/100005866753693',
    },
    {
      name: 'Kamil Iwaniuk',
      image: kamil,
      phone: '661823770',
      position: 'Członek Zarządu',
      messenger: 'https://m.me/kamil.iwaniuk.58',
    },
    {
      name: 'Maciej Scheffler',
      image: maciej,
      phone: '502693199',
      position: 'Członek Zarządu',
      messenger: 'https://m.me/maciek.scheffler',
    },
    {
      name: 'Jacek Rogowski',
      image: jacek,
      phone: '579117977',
      position: 'Informatyczna złota rączka',
      messenger: 'https://m.me/jacek.rogowski.39',
    },
    {
      name: 'Wioleta Sybal',
      image: wiola,
      phone: '661265500',
      position: 'Forum Ogień z Nieba',
      messenger: 'https://m.me/wiola.sybal',
    },
  ]
  return (
    <>
      {
        BreakpointsHelper.isMobile()
        ? (
            <Carousel
              data={admins}
              slide={(el) => (
                <Box sx={style.box}>
                  <img src={el.image} alt={el.name} style={style.image}/>
                  <Typography sx={{ textAlign: 'center' }}>{el.name}</Typography>
                  <Typography sx={{ textAlign: 'center' }}>{el.position}</Typography>
                  {
                    el?.phone?.length > 0 && (
                      <a href={`tel:${el.phone}`} style={{ marginBottom: BreakpointsHelper.isMobile() ? '5px' : '0' }}>
                        {t.contactPage.call}
                      </a>
                    )
                  }
                  <a href={el.messenger} target="_blank" rel="noreferrer">{t.contactPage.talk}</a>
                </Box>
              )}
            />
        )
        : (
            <Grid container>
              {
                admins?.map((el, index) => {
                  return (
                    <Grid key={index} item md={4} xs={12}>
                      <Box sx={style.box}>
                        <img src={el.image} alt={el.name} style={style.image}/>
                        <Typography sx={{ textAlign: 'center' }}>{el.name}</Typography>
                        <Typography sx={{ textAlign: 'center' }}>{el.position}</Typography>
                        {
                          el?.phone?.length > 0 && (
                            <a href={`tel:${el.phone}`}
                               style={{ marginBottom: BreakpointsHelper.isMobile() ? '5px' : '0' }}>
                              {t.contactPage.call}
                            </a>
                          )
                        }
                        <a href={el.messenger} target="_blank" rel="noreferrer">{t.contactPage.talk}</a>
                      </Box>
                    </Grid>
                  )
                })
              }
            </Grid>
        )
      }
    </>
  )
}

export default Contacts
