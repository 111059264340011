import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { Redirect, useHistory } from 'react-router'
import './assets/scss/App.scss'
import { PublicRoute } from './router/PublicRoute/PublicRoute'
import HomePage from './pages/HomePage/HomePage'
import RegulaminWplat from './pages/FaniPay/RegulaminWplat'
import RegulaminStrony from './pages/FaniPay/RegulaminStrony'
import FaniPayThanks from './pages/FaniPay/FaniPayThanks'
import FaniPayUnThanks from './pages/FaniPay/FaniPayUnThanks'
import PreviewLayout from './layouts/PreviewLayout/PreviewLayout'
import LangHelper from './helpers/LangHelper/LangHelper'

const App = () => {
  const history = useHistory()
  LangHelper.setDefaultLanguage(history)
  return (
    <Router basename={'/'}>
      <Switch>
        <PublicRoute exact path={'/'} component={() => <Redirect to={'/home'} />} layout={PreviewLayout} />
        <PublicRoute path={'/home'} component={HomePage} layout={PreviewLayout} />
        <PublicRoute path={'/regulamin-wplat'} component={RegulaminWplat} layout={PreviewLayout} />
        <PublicRoute path={'/regulamin-strony'} component={RegulaminStrony} layout={PreviewLayout} />
        <PublicRoute path={'/fanipay-success-dziekujemy'} component={FaniPayThanks} layout={PreviewLayout} />
        <PublicRoute path={'/fanipay-misfortune-dziekujemy'} component={FaniPayUnThanks} layout={PreviewLayout} />
      </Switch>
    </Router>
  )
}

export default App
