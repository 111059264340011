import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { Helmet } from 'react-helmet'
import { Grid, TextField } from '@mui/material'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import sprawozdanieFinansowe from '../../assets/files/sprawdozdanieFinansowe.pdf'
import sprawozdanieFinansowe2023 from '../../assets/files/Sprawozdanie finansowe 2023.pdf'
import sprawozdanieMerytoryczne from '../../assets/files/sprawozdanieMerytoryczne.pdf'
import sprawozdanieMerytoryczne2023 from '../../assets/files/Sprawozdanie merytoryczne 2023.pdf'
import statut from '../../assets/files/statut.pdf'
import polityka from '../../assets/files/polityka.pdf'
import darowizny from '../../assets/files/darowizny.pdf'
// import regulamin from '../../assets/files/regulamin.pdf'
import { useHistory } from 'react-router-dom'

const Footer = (props) => {
    const history = useHistory()
    const handleGoToPaymentRules = () => {
      history.push('/regulamin-wplat')
    }
    const handleGoToWWWRules = () => {
      history.push('/regulamin-strony')
    }

  const t = LocalStorageHelper.get('translation')

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Lifesurfers</title>
        <meta name="keywords" content="gry planszowe, Szczecin, planszówki Lifesurfers" />
        <meta
          name="description"
          content="Zarejestruj się i stwórz, dołącz do najbliższego wydarzenia, aby poczuć magię planszówek. Pamiętaj, nie liczy się Twój wiek czy doświadczenie w graniu."
        />
        <meta name="subject" content="Lifesurfers" />
        <meta name="copyright" content="Lifesurfers" />
        <meta name="language" content="PL" />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Jan Szymański, kontakt@your-site.pl" />
        <meta name="designer" content="Jan Szymański, kontakt@your-site.pl" />
        <meta name="copyright" content="Jan Szymański, kontakt@your-site.pl" />
        <meta name="owner" content="Jan Szymański, kontakt@your-site.pl" />
        <meta name="url" content="https://aplikacja.lifesurfers.pl" />
        <meta name="identifier-URL" content="https://aplikacja.lifesurfers.pl" />
        <meta name="category" content="Gry planszowe" />
        <meta name="distribution" content="Global" />
        <meta name="rating" content="General" />
        <meta name="revisit-after" content="7 days" />
        <meta httpEquiv="Expires" content="0" />
        <meta httpEquiv="Pragma" content="no-cache" />
        <meta httpEquiv="Cache-Control" content="no-cache" />
        <meta name="og:title" content="Lifesurfers" />
        <meta name="og:type" content="website" />
        <meta name="og:url" content="https://aplikacja.lifesurfers.pl" />
        <meta name="og:site_name" content="Lifesurfers" />
        <meta
          name="og:description"
          content="Zarejestruj się i stwórz, dołącz do najbliższego wydarzenia, aby poczuć magię planszówek. Pamiętaj, nie liczy się Twój wiek czy doświadczenie w graniu."
        />
        <meta name="fb:page_id" content="lifesurfers" />
        <meta name="og:email" content="fundacja@lifesurfers.pl" />
        <meta name="og:phone_number" content="500377540" />
        <meta name="og:locality" content="Szczecin" />
        <meta name="og:region" content="Zachodniopomorskie" />
        <meta name="og:postal-code" content="71-600" />
        <meta name="og:country-name" content="Polska" />
        <meta property="og:type" content="website" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta content="yes" name="apple-touch-fullscreen" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="format-detection" content="telephone=no" />
      </Helmet>
<div>
<Box
        sx={{
          display: 'flex',
          alignItems: BreakpointsHelper.isMobile() ? 'flex-start' : 'center',
          justifyContent: 'center',
          padding: '60px 50px',
          width: 'calc(100% - 100px)',
          borderTop: '1px solid rgba(0, 0, 0, 0.2)',
          flexDirection: BreakpointsHelper.isMobile() && 'column'
        }}
      >
        <Grid container spacing={4}>
          <Grid item md={3} xs={12}>
          <Typography
              sx={{ fontSize: '18px', marginBottom: '0px' }}
            >
              Regulaminy
            </Typography>
          <Typography
              sx={{
                marginRight: '10px',
                cursor: 'pointer'
              }}
            >
              <a href={polityka} target="_blank" rel="noreferrer">
               Polityka prywatności
              </a>
            </Typography>
            <Typography
              sx={{
                marginRight: '10px',
                cursor: 'pointer'
              }}
            >
              <a onClick={handleGoToWWWRules} href="">
                Regulamin serwisu
              </a>
            </Typography>
            <Typography
              sx={{
                marginRight: '10px',
                cursor: 'pointer'
              }}
            >
             <a onClick={handleGoToPaymentRules} href="">
                Regulamin darowizn
              </a>
            </Typography>

              {/* <form
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                action="https://api.your-site.pl/payu/"
                method="POST"
              >
                <input type="hidden" name="idPos" value="4289134" />
                <input type="hidden" name="oauthClientId" value="4289134" />
                <input type="hidden" name="signatureKey" value="6f7ba8beb5c57ac3675472e7e22913e9" />
                <input type="hidden" name="oauthClientSecret" value="bca0a3fd71763dea2256be4c2ab4bcb7" />
                <input type="hidden" name="name" value="Wsparcie finansowe Fundacji Life Surfers" />
                <input type="hidden" name="description" value="Wsparcie finansowe Fundacji Life Surfers" />
                <TextField
                  sx={{ marginBottom: '10px', width: '100%' }}
                  type="text"
                  name="totalAmount"
                  placeholder="Ile chcesz wesprzeć? (podaj w groszach)"
                  onChange={(e) => setTotalAmout(e.target.value)}
                  value={totalAmount}
                />
                <input type="hidden" name="unitPrice" value={totalAmount} />
                <input type="hidden" name="quantity" value="1" />
                <TextField
                  sx={{ marginBottom: '10px', width: '100%' }}
                  type="text"
                  name="firstName"
                  placeholder="Podaj imię"
                />
                <TextField
                  sx={{ marginBottom: '10px', width: '100%' }}
                  type="text"
                  name="lastName"
                  placeholder="Podaj nazwisko"
                />
                <TextField
                  sx={{ marginBottom: '10px', width: '100%' }}
                  type="text"
                  name="email"
                  placeholder="Podaj email"
                />
                <TextField
                  sx={{ marginBottom: '10px', width: '100%' }}
                  type="text"
                  name="phone"
                  placeholder="Podaj telefon"
                />
                <input type="hidden" name="buyerName" value="Life Surfers" />
                <input type="hidden" name="recipientEmail" value="fundacja@lifesurfers.pl" />
                <input type="hidden" name="recipientPhone" value="500377540" />
                <input type="hidden" name="street" value="ul. Parkowa 1/3" />
                <input type="hidden" name="postalBox" value="Szczecin" />
                <input type="hidden" name="postalCode" value="71-600" />
                <input type="hidden" name="city" value="Szczecin" />
                <input type="hidden" name="state" value="Zachodniopomorskie" />
                <input type="hidden" name="continueUrl" value="" />
                <input type="hidden" name="notifyUrl" value="" />
                <Button type="submit" variant="contained">
                  Przejdź do płatności
                </Button>
              </form> */}

          </Grid>

          <Grid item md={3} xs={12}>
            <Typography sx={{ fontSize: '18px', marginBottom: '0px' }}>Dokumenty</Typography>
            <Typography
              sx={{
                marginRight: '10px',
                cursor: 'pointer'
              }}
            >
              <a href={statut} target="_blank" rel="noreferrer">
                Statut
              </a>
            </Typography>
            <Typography
              sx={{
                marginRight: '10px',
                cursor: 'pointer'
              }}
            >
              <a href={sprawozdanieMerytoryczne} target="_blank" rel="noreferrer">
                Sprawozdanie Merytoryczne 2022
              </a>
            </Typography>
            <Typography
              sx={{
                marginRight: '10px',
                cursor: 'pointer'
              }}
            >
              <a href={sprawozdanieMerytoryczne2023} target="_blank" rel="noreferrer">
                Sprawozdanie Merytoryczne 2023
              </a>
            </Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography
              sx={{
                marginRight: '10px',
                cursor: 'pointer'
              }}
            >
              <a href={sprawozdanieFinansowe} target="_blank" rel="noreferrer">
                Sprawozdanie Finansowe 2022
              </a>
            </Typography>
            <Typography
              sx={{
                marginRight: '10px',
                cursor: 'pointer'
              }}
            >
              <a href={sprawozdanieFinansowe2023} target="_blank" rel="noreferrer">
                Sprawozdanie Finansowe 2023
              </a>
            </Typography>
          </Grid>
          {/* <Grid item md={3} xs={12}>
            <Typography sx={{ fontSize: '18px', marginBottom: '20px' }}>Regulaminy</Typography>
            <Typography
              sx={{
                marginRight: '10px',
                cursor: 'pointer'
              }}
            >
              <a href={polityka} target="_blank" rel="noreferrer">
                {t?.homePage?.footer.desc3}
              </a>
            </Typography>
            <Typography
              sx={{
                marginRight: '10px',
                cursor: 'pointer'
              }}
            >
              <a href={regulamin} target="_blank" rel="noreferrer">
                {t?.homePage?.footer.desc2}
              </a>
            </Typography>
            <Typography
              sx={{
                marginRight: '10px',
                cursor: 'pointer'
              }}
            >
              <a href={darowizny} target="_blank" rel="noreferrer">
                Regulamin darowizn
              </a>

            </Typography>
          </Grid> */}
          <Grid item md={12} xs={12}>
            <Typography sx={{ marginTop: '20px' }}>
              @ {new Date().getFullYear()} wszelkie prawa zastrzeżone przez Lifesurfers. Strona stworzona przez{' '}
              <a href="https://your-site.pl" target="_blank" rel="noreferrer">
                Your site
              </a>
              .
            </Typography>
          </Grid>
        </Grid>
      </Box>
</div>
    </div>
)
}
export { Footer }
