import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import hexToRgba from 'hex-to-rgba'
import ToastProvider from './components/organisms/ToastProvider/ToastProvider'
import Logo from '../src/assets/images/logo_vertical.png'

const MOUNT_NODE = document.getElementById('root')

const root = ReactDOM.createRoot(document.getElementById('root'))

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#ce0707',
      black: '#000000',
      white: '#ffffff',
    },
    secondary: {
      main: '#66bb6a',
      second: '#ab47bc',
      grey: '#B5B5B5',
      greyLight: '#EBEBEB',
      greyDark: '#767373'
    },
    toasts: {
      success: '#2e7d32',
      warning: '#ed6c02',
      error: '#d32f2f',
      info: '#0288d1'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  mixins: {
    backgroundOpacity: (background, opacity = 0.3) =>
      `background: ${hexToRgba(background, opacity)};`,
    colorOpacity: (color, opacity = 1) =>
      `color: ${hexToRgba(color, opacity)};`,
    fillOpacity: (color, opacity = 1) =>
      `fill: ${hexToRgba(color, opacity)};`,
    borderColorOpacity: (size, line, color, opacity = 1) =>
      `border: ${size || '1px'} ${line || 'solid'} ${hexToRgba(color, opacity)};`,
    pxToRem: (px, pxStart = 16) =>
      `${Math.round((px / pxStart) * 1000) / 1000}rem`
  },
  typography: {
    useNextVariants: true
  },
  config: {
    maxToasts: 5,
    logo: Logo,
  }
})

// eslint-disable-next-line jest/require-hook
root.render(
  <ToastProvider theme={theme}>
    <ThemeProvider theme={theme}>
        <App/>
    </ThemeProvider>
  </ToastProvider>,
  MOUNT_NODE
)
