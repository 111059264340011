import Section from '../../components/atoms/Section/Section'
import Row from '../../components/atoms/Row/Row'
import website3 from '../../assets/images/website/website3.jpg'
import website4 from '../../assets/images/website/website4.jpg'
import radoscSeksu from '../../assets/images/radosc_seksu.jpg'
import googleImages from '../../assets/images/pcloud.png'
import forum from '../../assets/images/poster_forum_pion_qr.jpg'
import Button from '../../components/atoms/Forms/Button/Button'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { Helmet } from 'react-helmet'
import SliderThanks from '../../components/molecules/Slider/SliderThanks'
import { Footer } from '../HomePage/Footer'
import Contacts from '../../components/organisms/Contacts/Contacts'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import { Grid, TextField } from '@mui/material'
import React, { useState } from 'react'

const FaniPayThanks = (props) => {
  const t = LocalStorageHelper.get('translation')
  const [totalAmount, setTotalAmout] = useState(null)
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Lifesurfers</title>
        <meta name="keywords" content="gry planszowe, Szczecin, planszówki Lifesurfers" />
        <meta
          name="description"
          content="Zarejestruj się i stwórz, dołącz do najbliższego wydarzenia, aby poczuć magię planszówek. Pamiętaj, nie liczy się Twój wiek czy doświadczenie w graniu."
        />
        <meta name="subject" content="Lifesurfers" />
        <meta name="copyright" content="Lifesurfers" />
        <meta name="language" content="PL" />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Jan Szymański, kontakt@your-site.pl" />
        <meta name="designer" content="Jan Szymański, kontakt@your-site.pl" />
        <meta name="copyright" content="Jan Szymański, kontakt@your-site.pl" />
        <meta name="owner" content="Jan Szymański, kontakt@your-site.pl" />
        <meta name="url" content="https://aplikacja.lifesurfers.pl" />
        <meta name="identifier-URL" content="https://aplikacja.lifesurfers.pl" />
        <meta name="category" content="Gry planszowe" />
        <meta name="distribution" content="Global" />
        <meta name="rating" content="General" />
        <meta name="revisit-after" content="7 days" />
        <meta httpEquiv="Expires" content="0" />
        <meta httpEquiv="Pragma" content="no-cache" />
        <meta httpEquiv="Cache-Control" content="no-cache" />
        <meta name="og:title" content="Lifesurfers" />
        <meta name="og:type" content="website" />
        <meta name="og:url" content="https://aplikacja.lifesurfers.pl" />
        <meta name="og:site_name" content="Lifesurfers" />
        <meta
          name="og:description"
          content="Zarejestruj się i stwórz, dołącz do najbliższego wydarzenia, aby poczuć magię planszówek. Pamiętaj, nie liczy się Twój wiek czy doświadczenie w graniu."
        />
        <meta name="fb:page_id" content="lifesurfers" />
        <meta name="og:email" content="fundacja@lifesurfers.pl" />
        <meta name="og:phone_number" content="500377540" />
        <meta name="og:locality" content="Szczecin" />
        <meta name="og:region" content="Zachodniopomorskie" />
        <meta name="og:postal-code" content="71-600" />
        <meta name="og:country-name" content="Polska" />
        <meta property="og:type" content="website" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta content="yes" name="apple-touch-fullscreen" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="format-detection" content="telephone=no" />
      </Helmet>
      <SliderThanks {...props} />
      <Footer {...props} />
    </div>
  )
}

export default FaniPayThanks
