import React from 'react'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import { Height } from '@mui/icons-material'

const Section = ({ title, description, image, object, textAlign, html }) => {
  const style = {
    root: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '50px',
    },
    title: {
      fontSize: BreakpointsHelper.isMobile() ? '24px' : '42px',
      color: '#ce0707',
      fontWeight: '700',
    },
    description: {
      color: '#222325',
      fontSize: '18px',
      fontWeight: '400',
      marginBottom: '10px',
      marginTop: '10px',
      maxWidth: '100%',
    },
    image: {
      width: '100%',
      marginTop: '0px',
    },
    img: {
      width: '100%',
      boxShadow: '2px 5px 20px 10px rgb(18, 26, 68, .15)',
    },
  }
  return (
		<div style={style.root}>
			{ title && <div style={style.title} className="animate__animated animate__fadeIn animate__slower">{title}</div> }
			{ description && !html && <div style={style.description} className="animate__animated animate__fadeIn animate__slower" dangerouslySetInnerHTML={{ __html: description }}/> }
      { description && html && <div style={style.description} className="animate__animated animate__fadeIn animate__slower">{description}</div> }
			{ object && <div style={style.description} className="animate__animated animate__fadeIn animate__slower">{object}</div> }
			{
				image && (
					<div style={style.image}>
            <img src={image} alt={title || 'zdjęcie'} style={style.img} className="animate__animated animate__fadeIn animate__slower" />
					</div>
				)
			}
		</div>
  )
}

export default Section
